import idCard from "idcard"

/* 校验身份证 */
export const checkIdCard = (id) => {
  return idCard.verify(id)
}

/* 手机号校验 */
export const checkMobile = (phone) => {
  const reg = new RegExp(/^1[3-9]\d{9}$/)
  const check = reg.test(phone)
  return check
}

/* 用于 ant-form 的 validator */
export const IdCardValidator = (id) => {
  if (!id) return Promise.reject("不能为空")
  else if (!checkIdCard(id)) return Promise.reject("格式错误")
  else return Promise.resolve()
}

export const MobileValidator = (phone) => {
  if (!phone) return Promise.reject("不能为空")
  else if (!checkMobile(phone)) return Promise.reject("格式错误")
  else return Promise.resolve()
}
