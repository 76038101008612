export const API_BASE = process.env.VUE_APP_BASE_URL
export const BASE_PATH = process.env.VUE_APP_BASE_PATH
export const API_URL = API_BASE + BASE_PATH

export const ID_TOKEN_KEY = "id_token"

export const LOGIN_CHANNEL = "desktop"

export const useMock = false
export const MOCK_API = ""
export const mockURL = MOCK_API

/* 
15305778853
chi@85386919
*/

// 单点登录 url
// 测试环境需配置 host：127.0.0.1 hqzczd.abadata.cn
// 1. 前往政务网登录 => 重定向到 /app/sc_redirect_uri
export const SSOLoginURL =
  "https://open.sczwfw.gov.cn/open-platform-oauth/oauth/authorize?client_id=0003&scope=user_info&code=1&response_type=code&redirect_uri=https://open.sczwfw.gov.cn/open-platform-exchange/exchange/getResource?resourceCode=ZYZC2023011000271400%26applyCode=YYSQ2023011000002569%26sysCode=0003"
// 2. /app/sc_redirect_uri 中跳转下面地址 => 回到 /app/unifiedlogin 获取 code
export const RedirectURI =
  "http://open.sczwfw.gov.cn/open-platform-exchange/exchange/getResource?resourceCode=ZYZC2019112500000198&applyCode=YYSQ2022120900002547&sysCode=0003&response_type=code&client_id=01AB&redirect_uri=http://hqzczd.abadata.cn/app/unifiedlogin&scope=oauth_info"
// 3. 退出登录接口
export const LogoutURL =
  "http://open.sczwfw.gov.cn/open-platform-exchange/exchange/getResource?resourceCode=ZYZC2019112500000197&applyCode=YYSQ2022120900002546&sysCode=0003&target=http://hqzczd.abadata.cn/app/"
