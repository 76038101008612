import { defineStore } from "pinia"
import { getLocalStorage, setLocalStorage, isLogin } from "@/utils"
import { useSaveToken } from "@/utils"
import { appServe, usrServe } from "@/service"

export const useAppStore = defineStore("app", {
  state: () => ({
    currentArea: getLocalStorage("currentArea", ""),
    currentApplicable: getLocalStorage("currentApplicable", ""),
    currentPolicy: getLocalStorage("currentPolicy", ""),
    isLogin: isLogin(),
    userInfo: {},
    enums: {}
  }),
  getters: {
    isLegal() {
      const { ssoUserType } = this.userInfo
      return ssoUserType === "LEGAL_PERSON"
    }
  },
  actions: {
    setCurrentStorage(key, value) {
      this[key] = value
      setLocalStorage(key, value)
    },
    testLogin(id) {
      appServe.testLogin(id).then((res) => {
        const { success, data } = res
        if (success) {
          useSaveToken(data)
          this.updateIsLogin()
        }
      })
    },
    updateIsLogin() {
      const sts = isLogin()
      this.isLogin = sts
      this.updateUsrInfo()
    },
    updateUsrInfo() {
      if (this.isLogin) {
        usrServe.details().then((res) => {
          this.userInfo = res.data
        })
      } else {
        this.userInfo = {}
      }
    },
    getEnums() {
      appServe.getEnums().then((res) => {
        const { success, data } = res
        if (success) {
          const maps = Object.keys(data).reduce((maps, key) => {
            const items = data[key].map((item) => {
              const { enum_key, enum_value, order_id } = item
              return { value: enum_key, label: enum_value, order: order_id }
            })
            maps[key] = items.sort((a, b) => a.order - b.order)
            return maps
          }, {})

          this.enums = { ...this.enums, ...maps }
        }
      })
    },
    getAreas() {
      appServe.getAreas().then((res) => {
        const { success, data } = res
        if (success) {
          this.enums["commonAreas"] = data.map((item) => {
            const { id, name, adminDept } = item
            return { value: id, label: name, adminDept }
          })
        }
      })
    },
    init() {
      this.updateUsrInfo()
      this.getEnums()
      this.getAreas()
    }
  }
})
