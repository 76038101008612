import { defineStore } from "pinia"

export const useFileStore = defineStore("file", {
  state: () => ({
    fileCacheList: {} // 附件缓存
  }),
  actions: {
    // 缓存附件
    cacheFile(data) {
      const { file, uuid, name } = data
      const names = name.split(".")
      const ext = names[names.length - 1]
      const imgExts = ["png", "jpeg", "jpg"]
      const config = {
        ...data,
        isImage: imgExts.includes(ext),
        path: URL.createObjectURL(file)
      }
      this.fileCacheList[uuid] = config
      return config
    },
    // 获取缓存附件
    getCacheFile(uuid) {
      const config = this.fileCacheList[uuid]
      if (config) return config
      else return false
    }
  }
})
