import { ApiService } from "@/utils"

export const appServe = {
  getUsrInfo() {
    return ApiService.query(`login/user_info/`)
  },
  testLogin(id) {
    return ApiService.post(`login/test-login/?id=${id}`)
  },
  getEnums() {
    return ApiService.query(`dict/all_enum/`)
  },
  getAreas() {
    return ApiService.query(`common/areas/`)
  },
  getProjectList(params) {
    return ApiService.query(`projects/`, params)
  },
  getPublicityList(params) {
    return ApiService.query(`publicity/`, params)
  },
  getProject(projectId) {
    return ApiService.query(`projects/${projectId}/base/`)
  },
  getBatch(id) {
    return ApiService.query(`batch/${id}/`)
  },
  getGuide(projectId) {
    return ApiService.query(`projects/${projectId}/guide/`)
  }
}

export const policyServe = {
  list(params) {
    return ApiService.query(`policy/policies/`, params)
  },
  details(id) {
    return ApiService.query(`policy/${id}/`)
  }
}

export const annoServe = {
  list(params) {
    return ApiService.query(`announcements/`, params)
  },
  details(id) {
    return ApiService.query(`announcements/${id}/`)
  }
}

export const instServe = {
  list(params) {
    return ApiService.query("inst/", params)
  },
  details(id) {
    return ApiService.query(`inst/${id}/`)
  },
  historic(instId) {
    return ApiService.query(`inst/${instId}/historic/`)
  },
  // 创建工单
  createInst(batchId) {
    return ApiService.post(`inst/?batchId=${batchId}`)
  },
  // 提交工单
  declare(taskId, form) {
    return ApiService.post(`task/${taskId}/declare/`, {
      form,
      comment: "提交申请",
      submitType: "SUBMIT"
    })
  },
  // 暂存
  save(taskId, form) {
    return ApiService.post(`task/data/save/task/?taskId=${taskId}`, form)
  },
  // 暂存
  recreate(instId) {
    return ApiService.post(`inst/${instId}/recreate/`)
  },
  // 取回
  retrieve(instId) {
    return ApiService.post(`inst/${instId}/retrieve/`, { comment: "取回工单" })
  },
  // 任务详情 => 为了获取权限
  taskDetails(taskId) {
    return ApiService.query(`task/${taskId}/detail/`)
  },
  // 更新票据
  updateInvoice(instId, data) {
    return ApiService.post(`apply/invoice/?instId=${instId}`, data)
  },
  // 获取票据
  getInvoice(instId) {
    return ApiService.query(`apply/invoice/`, { instId })
  },
  // 获取公示详情
  getPublic(instId) {
    return ApiService.query(`inst/${instId}/publicity/`)
  }
}

export const usrServe = {
  getToken(code) {
    return ApiService.query(`sso/auth/abz/`, { code })
  },
  details() {
    return ApiService.query(`sso/auth/abz/userinfo/`)
  },
  update(uuid, params) {
    return ApiService.put(`sso/auth/${uuid}/`, params)
  },
  sendSms(phoneNum, receiver) {
    return ApiService.query(`common/sms/captcha/`, { phoneNum, receiver })
  }
}

export const searchServe = {
  list(params) {
    return ApiService.query(`elasticsearch/`, params)
  }
}

// 通用工具
export const componentServe = {
  // 图片上传，返回 id
  uploadFile(file) {
    return ApiService.formPost(`common/files/upload/`, {
      file,
      name: file.name
    })
  },
  async readFile(id) {
    const res = await ApiService.query(
      `common/files/download/`,
      { id },
      { getResponse: true }
    )
    // 读取成功时没有 success
    if (res.success !== false) {
      const { response } = res
      const disposition = response.headers.get("content-disposition") || ""
      const blob = await response.blob()
      return {
        success: true,
        data: {
          uuid: id,
          file: blob,
          name: decodeURIComponent(disposition.split("=")[1] || "")
        }
      }
    }
    return res
  }
}
