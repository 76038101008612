import ApiService from "./request"
import { destroyToken, getWithExpiry, saveToken } from "@dg/utils"
import { ID_TOKEN_KEY, LogoutURL, SSOLoginURL } from "@/common/config"
import { useAppStore } from "@/store"
import { computed, onMounted, onUnmounted } from "vue"
import { saveAs } from "file-saver"
import router from "@/router"

export const useService = (url) => ({
  list(params) {
    return ApiService.query(`${url}`, params)
  },
  create(params) {
    return ApiService.post(`${url}`, params)
  },
  update(id, params) {
    return ApiService.put(`${url}${id}/`, params)
  },
  detail(id) {
    return ApiService.query(`${url}${id}/`)
  },
  del(id) {
    return ApiService.delete(`${url}${id}`)
  }
})

// 下载 hooks
export function downloadFile(config) {
  saveAs(config.path, config.name)
}

// 使用特定枚举值
export function useEnums(keys = []) {
  const appStore = useAppStore()

  const maps = {}

  keys.map((key) => {
    maps[key] = computed(() => {
      return appStore.enums?.[key] || []
    })
  })

  return maps
}

// 使用全部枚举值
export function useAllEnums() {
  const appStore = useAppStore()

  const maps = {}

  // 字典枚举值
  Object.keys(appStore.enums).forEach((key) => {
    maps[key] = computed(() => {
      return appStore.enums?.[key] || []
    })
  })

  return maps
}

// 缓存 token
export const useSaveToken = (token) => {
  const [, config] = token.split(".")
  const { exp } = JSON.parse(atob(config)) // 超时秒数
  const time = new Date().getTime() // 当前毫秒数
  if (token) {
    saveToken(ID_TOKEN_KEY, token, exp * 1000 - time - 60000)
  }
}

// BaseTable 方法
export const useTableMethods = (tableRef) => {
  const setPagination = (opt) => {
    tableRef.value.tableMethods.setPagination(opt)
  }

  const reload = () => {
    tableRef.value.tableMethods.reload()
  }

  const redoHeight = () => {
    tableRef.value.tableMethods.redoHeight()
  }

  return {
    setPagination: setPagination,
    redoHeight: redoHeight,
    reload() {
      setPagination({ current: 1 })
      reload()
    },
    // 刷新不会重置页码
    refresh() {
      reload()
    }
  }
}

// 页面显隐
export const useVisibleChange = (callback) => {
  const activated = () => {
    const isHidden = document.hidden
    if (!isHidden) callback()
  }
  onMounted(() => {
    document.addEventListener("visibilitychange", activated)
  })
  onUnmounted(() => {
    document.removeEventListener("visibilitychange", activated)
  })
}

// localStorage
export const getLocalStorage = (key, defaultValue) => {
  try {
    const config = localStorage.getItem(key)
    return JSON.parse(config)?.value || defaultValue
  } catch (err) {
    console.error(err)
    return defaultValue
  }
}
export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify({ value }))
}

// login
export const isLogin = () => {
  const token = getWithExpiry(ID_TOKEN_KEY)
  if (token && !token.isExpired) return true
  else return false
}
// 登录
export const useLogin = () => {
  const appStore = useAppStore()
  return {
    useTestLogin: (id) => {
      return () => {
        appStore.testLogin(id)
      }
    },
    login: () => {
      window.location.href = SSOLoginURL
    }
  }
}
// 登出
export const useLogout = () => {
  const appStore = useAppStore()
  const logout = () => {
    destroyToken(ID_TOKEN_KEY)
    appStore.updateIsLogin()
  }

  return {
    testLogout: logout,
    logout: () => {
      logout()
      window.location.href = LogoutURL
    }
  }
}
// 重登
export const toLogin = () => {
  router.push("/login") // 跳转系统登录页
}
