import { createRouter, createWebHistory } from "vue-router"
import { message as Message } from "ant-design-vue"
import { isLogin } from "@/utils"

const routes = [
  {
    path: "/",
    name: "app",
    component: () => import("@/views/Layout"),
    children: [
      // 首页
      {
        path: "",
        name: "home",
        component: () => import(/* webpackChunkName: "home" */ "@/views/Home")
      },
      // 搜索
      {
        path: "search",
        name: "search",
        component: () =>
          import(/* webpackChunkName: "search" */ "@/views/Search")
      },
      // 申报
      {
        path: "apply",
        name: "apply",
        children: [
          {
            path: "",
            name: "applyList",
            component: () =>
              import(/* webpackChunkName: "apply-list" */ "@/views/Apply/List")
          },
          {
            path: "statement",
            name: "statement",
            component: () =>
              import(
                /* webpackChunkName: "statement" */ "@/views/Apply/Statement"
              ),
            meta: { needLogin: true }
          },
          {
            path: "form",
            name: "form",
            component: () =>
              import(/* webpackChunkName: "form" */ "@/views/Apply/Form"),
            meta: { needLogin: true }
          }
        ]
      },
      // 奖补公示
      {
        path: "publicity",
        name: "publicity",
        children: [
          {
            path: "",
            name: "publicityList",
            component: () =>
              import(
                /* webpackChunkName: "publicity-list" */ "@/views/Publicity"
              )
          }
        ]
      },
      // 指南
      {
        path: "guide",
        name: "guide",
        children: [
          {
            path: "",
            name: "guideList",
            component: () =>
              import(/* webpackChunkName: "guide-list" */ "@/views/Guide/List")
          },
          {
            path: "details",
            name: "guideDetails",
            component: () =>
              import(
                /* webpackChunkName: "guide-details" */ "@/views/Guide/Details"
              )
          }
        ]
      },
      // 政策文件
      {
        path: "policy",
        name: "policy",
        children: [
          {
            path: "",
            name: "policyList",
            component: () =>
              import(
                /* webpackChunkName: "policy-list" */ "@/views/Policy/List"
              )
          },
          {
            path: "details",
            name: "policyDetails",
            component: () =>
              import(
                /* webpackChunkName: "policy-details" */ "@/views/Policy/Details"
              )
          }
        ]
      },
      // 申报通知
      {
        path: "announcement",
        name: "announcement",
        children: [
          {
            path: "",
            name: "annoList",
            component: () =>
              import(
                /* webpackChunkName: "anno-list" */ "@/views/Announcement/List"
              )
          },
          {
            path: "details",
            name: "annoDetails",
            component: () =>
              import(
                /* webpackChunkName: "anno-details" */ "@/views/Announcement/Details"
              )
          }
        ]
      },
      // 办理进度
      {
        path: "progress",
        name: "progress",
        component: () =>
          import(/* webpackChunkName: "progress" */ "@/views/Progress"),
        meta: { needLogin: true }
      },
      // 登录
      {
        path: "login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "@/views/Login")
      },
      // 用户信息
      {
        path: "usr",
        name: "usr",
        component: () => import(/* webpackChunkName: "usr" */ "@/views/Usr"),
        meta: { needLogin: true }
      }
    ]
  },
  {
    // 登录重定向
    path: "/sc_redirect_uri",
    name: "redirect",
    component: () =>
      import(/* webpackChunkName: "redirect" */ "@/views/Login/redirect")
  },
  // 登录重定向解析 code
  {
    path: "/unifiedlogin",
    name: "unifiedlogin",
    component: () =>
      import(
        /* webpackChunkName: "unifiedlogin" */ "@/views/Login/unifiedlogin"
      )
  }
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
  routes
})

router.beforeEach((to, form, next) => {
  const { meta } = to
  const { title = process.env.VUE_APP_TITLE, needLogin } = meta
  document.title = title
  if (needLogin && !isLogin()) {
    Message.error("用户未登录")
    next("/login")
  } else next()
})

export default router
