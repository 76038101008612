import { API_URL, ID_TOKEN_KEY, LOGIN_CHANNEL } from "@/common/config"
import apiService, { extend } from "@dg/request"
import { getWithExpiry } from "@dg/utils"
import { message as Message } from "ant-design-vue"
import { toLogin, useSaveToken } from "./tools"

apiService.extend({
  prefix: API_URL,
  getResponse: true,
  errorHandler: function (errObj) {
    const { response, data } = errObj
    const { status } = response
    const { code = status, error, message: errMsg = error } = data

    const loginTest = status === 401 || errMsg === "当前登录的用户非法！"
    if (loginTest) toLogin()
    if (!loginTest) Message.error(`${code}：${errMsg}`)
    return Promise.reject({ success: false, ...data })
  }
})

const tokenRequest = extend({})
// 长时间连续操作 自动刷新token
apiService.request.use(async (ctx, next) => {
  const { req } = ctx
  const { prefix, headers } = req.options
  const token = getWithExpiry(ID_TOKEN_KEY)
  if (token) {
    if (token.isExpired) {
      try {
        const data = { token: token.value, login_channel: LOGIN_CHANNEL }
        const res = await tokenRequest.post(
          `${prefix}dg_admin/v2/refresh-token/`,
          { data }
        )
        if (res.data.token) {
          useSaveToken(res.data.token)
          ctx.req.options.headers = {
            Authorization: `Bearer ${res.data.token}`,
            ...headers
          }
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      ctx.req.options.headers = {
        Authorization: `Bearer ${token.value}`,
        ...headers
      }
    }
  }

  await next()
})

// 请求数据处理
apiService.request.use(async (ctx, next) => {
  await next()
  const { res } = ctx
  const { code } = res.data
  if (code >= 200 && code < 300) {
    ctx.res = { success: true, ...res.data }
  }
})

export default apiService
